<div class="main-wrapper">

    <app-navbar></app-navbar>

    <div class="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
        <div class="container eduvibe-animated-shape">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-inner text-start">
                        <div class="page-title">
                            <h3 class="title">{{ 'page.faq.head.title' | translate }}</h3>
                        </div>
                        <nav class="edu-breadcrumb-nav">
                            <ol class="edu-breadcrumb d-flex justify-content-start liststyle">
                                <li class="breadcrumb-item"><a href="/">{{ 'breadcrumbs.home' | translate }}</a></li>
                                <li class="separator"><i class="ri-arrow-drop-right-line"></i></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ 'breadcrumbs.terms_conditions' | translate }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-5">
                        <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-6">
                        <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="edu-blog-details-area edu-section-gap bg-color-white">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-10 offset-lg-1">
                    <div class="blog-details-1 style-variation3">

                        <div class="content-blog-top">
                            <h4 class="title">{{ 'page.terms_conditions.head.title' | translate }}</h4>
                        </div>

                        <div class="blog-main-content">
                            <p>{{ 'page.terms_conditions.head.description_1' | translate }}</p>
                            <p>{{ 'page.terms_conditions.head.description_2' | translate }}</p>
                            <p>{{ 'page.terms_conditions.head.description_3' | translate }}</p>
                            <p>{{ 'page.terms_conditions.head.description_4' | translate }}</p>
                            <p>{{ 'page.terms_conditions.head.description_5' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
<div class="rn-progress-parent">
    <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
