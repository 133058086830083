    <div class="main-wrapper">

        <app-navbar></app-navbar>

        <div class="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div class="container eduvibe-animated-shape">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-inner text-start">
                            <div class="page-title">
                                <h3 class="title">{{ 'page.programs.head.title' | translate }}</h3>
                            </div>
                            <nav class="edu-breadcrumb-nav">
                                <ol class="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li class="breadcrumb-item"><a href="/">{{ 'breadcrumbs.home' | translate }}</a></li>
                                    <li class="separator"><i class="ri-arrow-drop-right-line"></i></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{ 'breadcrumbs.programs' | translate }}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div class="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-4">
                            <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-5">
                            <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-6">
                            <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="edu-course-details-area edu-section-gap bg-color-white">
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-12">
                        <div class="main-image thumbnail">
                            <img class="radius-small" src="assets/images/school/img3.JPG" alt="Banner Images">
                        </div>
                    </div>
                </div>

                <div class="row g-12">
                    <div class="col-xl-12 col-lg-12">
                        <div class="course-details-content mt-5">

                                <p>{{ 'page.programs.body.description_1' | translate }}</p>
                                <p>{{ 'page.programs.body.description_2' | translate }}</p>
                                <p>{{ 'page.programs.body.description_3' | translate }}</p>
                                <p>{{ 'page.programs.body.description_4' | translate }}</p>
                                <p>{{ 'page.programs.body.description_5' | translate }}</p>
                                <p>{{ 'page.programs.body.description_6' | translate }}</p>

                            <ul class="edu-course-tab nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <img class="mt-1" src="../../../../assets/images/flag/i_france.png" alt="icon-france" />
                                    <button class="nav-link active" id="french-tab" data-bs-toggle="tab" data-bs-target="#french" type="button" role="tab" aria-controls="french" aria-selected="true">{{ 'page.programs.header.french' | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <img class="mt-1" src="../../../../assets/images/flag/i_usa.png" alt="icon-usa" />
                                    <button class="nav-link" id="english-tab" data-bs-toggle="tab" data-bs-target="#english" type="button" role="tab" aria-controls="english" aria-selected="false">{{ 'page.programs.header.english' | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <img class="mt-1" src="../../../../assets/images/flag/i_spain.png" alt="icon-spain" />
                                    <button class="nav-link" id="spanish-tab" data-bs-toggle="tab" data-bs-target="#spanish" type="button" role="tab" aria-controls="spanish" aria-selected="false">{{ 'page.programs.header.spanish' | translate }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <img class="mt-1" src="../../../../assets/images/flag/i_germany.png" alt="icon-germany" />
                                    <button class="nav-link" id="german-tab" data-bs-toggle="tab" data-bs-target="#german" type="button" role="tab" aria-controls="german" aria-selected="false">{{ 'page.programs.header.german' | translate }}</button>
                                </li>
                            </ul>

                            <div class="tab-content" id="myTabContent">

                                <div class="tab-pane fade active show"  id="french" role="tabpanel" aria-labelledby="french-tab">
                                    <div class="course-tab-content">

                                        <ul>
                                            <li>{{ 'page.programs.fr.description_1' | translate }}</li>
                                            <li>{{ 'page.programs.fr.description_2' | translate }}</li>
                                            <li>{{ 'page.programs.fr.description_3' | translate }}</li>
                                            <li>{{ 'page.programs.fr.description_4' | translate }}</li>
                                            <li>{{ 'page.programs.fr.description_5' | translate }}</li>
                                        </ul>

                                        <h5 class="pt--20">{{ 'page.programs.fr.category_1' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample4">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingFour">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                        {{ 'page.programs.fr.program_1' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseFour" class="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample4">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_1_time_1' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_1_day_2' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_1_time_2' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_1_day_3' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_1_time_3' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_1_day_4' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_1_time_4' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_1_day_5' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_1_time_5' | translate }}</i></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <h5 class="pt--60">{{ 'page.programs.fr.category_2' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample9">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingNine">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                                                        {{ 'page.programs.fr.program_2' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseNine" class="accordion-collapse collapse show" aria-labelledby="headingNine" data-bs-parent="#accordionExample9">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_2_day_1' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_2_time_1' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_2_day_2' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_2_time_2' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.fr.program_2_day_3' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.fr.program_2_time_3' | translate }}</i></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="tab-pane fade"              id="english" role="tabpanel" aria-labelledby="english-tab">
                                    <div class="course-tab-content">
                                        <ul>
                                            <li>{{ 'page.programs.en.description_1' | translate }}</li>
                                            <li>{{ 'page.programs.en.description_2' | translate }}</li>
                                            <li>{{ 'page.programs.en.description_3' | translate }}</li>
                                            <li>{{ 'page.programs.en.description_4' | translate }}</li>
                                            <li>{{ 'page.programs.en.description_5' | translate }}</li>
                                            <li>{{ 'page.programs.en.description_6' | translate }}</li>
                                        </ul>

                                        <h5 class="pt--20">{{ 'page.programs.en.category_1' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample1">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingOne">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        {{ 'page.programs.en.program_1' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_1_time_1' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_1_time_1' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_1_time_1' | translate }}</div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 class="pt--60">{{ 'page.programs.en.category_2' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample2">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingTwo">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        {{ 'page.programs.en.program_2' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample2">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_2_day_1' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_2_time_1' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_2_day_2' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_2_time_2' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_2_day_3' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_2_time_3' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_2_day_4' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_2_time_4' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_2_day_5' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_2_time_5' | translate }}</div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="edu-accordion-02 mt--60" id="accordionExample12">
                                            <div class="edu-accordion-item bg-active">
                                                <div class="edu-accordion-header" id="headingTen">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                                        {{ 'page.programs.en.program_3' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseTen" class="accordion-collapse collapse show" aria-labelledby="headingTen" data-bs-parent="#accordionExample12">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_3_day_1' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_3_time_1' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_3_day_2' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_3_time_2' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_3_day_3' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_3_time_3' | translate }}</div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i>{{ 'page.programs.en.program_3_day_4' | translate }}</div>
                                                                <div class="icon"><i class=""></i>{{ 'page.programs.en.program_3_time_4' | translate }}</div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="tab-pane fade"              id="spanish" role="tabpanel" aria-labelledby="spanish-tab">
                                    <div class="course-tab-content">

                                        <ul>
                                            <li>{{ 'page.programs.es.description_1' | translate }}</li>
                                            <li>{{ 'page.programs.es.description_2' | translate }}</li>
                                            <li>{{ 'page.programs.es.description_3' | translate }}</li>
                                            <li>{{ 'page.programs.es.description_4' | translate }}</li>
                                            <li>{{ 'page.programs.es.description_5' | translate }}</li>
                                        </ul>

                                        <h5 class="pt--60">{{ 'page.programs.es.category_1' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample6">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingSix">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                                        {{ 'page.programs.es.program_1' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#accordionExample6">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.es.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.es.program_1_time_1' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.es.program_1_day_2' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.es.program_1_time_2' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.es.program_1_day_3' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.es.program_1_time_3' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.es.program_1_day_4' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.es.program_1_time_4' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.es.program_1_day_5' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.es.program_1_time_5' | translate }}</i></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tab-pane fade"              id="german" role="tabpanel" aria-labelledby="german-tab">
                                    <div class="course-tab-content">

                                        <ul>
                                            <li>{{ 'page.programs.gr.description_1' | translate }}</li>
                                            <li>{{ 'page.programs.gr.description_2' | translate }}</li>
                                            <li>{{ 'page.programs.gr.description_3' | translate }}</li>
                                            <li>{{ 'page.programs.gr.description_4' | translate }}</li>
                                            <li>{{ 'page.programs.gr.description_5' | translate }}</li>
                                        </ul>

                                        <h5 class="pt--20">{{ 'page.programs.gr.category_1' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExample7">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingSeven">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                                        {{ 'page.programs.gr.program_1' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseSeven" class="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#accordionExample7">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_1_day_1' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_1_time_1' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_1_day_2' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_1_time_2' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_1_day_3' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_1_time_3' | translate }}</i></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <h5 class="mt--20">{{ 'page.programs.gr.category_1' | translate }}</h5>
                                        <div class="edu-accordion-02" id="accordionExampleEight">
                                            <div class="edu-accordion-item bg-active">

                                                <div class="edu-accordion-header" id="headingEight">
                                                    <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                                        {{ 'page.programs.gr.program_1' | translate }}
                                                    </button>
                                                </div>
                                                <div id="collapseEight" class="accordion-collapse collapse show" aria-labelledby="headingEight" data-bs-parent="#accordionExample8">
                                                    <div class="edu-accordion-body">
                                                        <ul>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_2_day_1' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_2_time_1' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_2_day_2' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_2_time_2' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_2_day_3' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_2_time_3' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_2_day_4' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_2_time_4' | translate }}</i></div>
                                                            </li>
                                                            <li>
                                                                <div class="text"><i class="icon-time-line"></i> {{ 'page.programs.gr.program_2_day_5' | translate }}</div>
                                                                <div class="icon"><i class="">{{ 'page.programs.gr.program_2_time_5' | translate }}</i></div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <app-footer></app-footer>

    </div>
    <div class="rn-progress-parent">
        <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
