<div class="main-wrapper">

    <app-navbar></app-navbar>

    <!-- Start Sldier Area  -->
    <div class="slider-area banner-style-4 bg-image">
        <div class="wrapper d-flex align-items-center home-four-banner-wrapper">
            <div class="container eduvibe-animated-shape">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-6 order-2 order-lg-1">
                        <div class="inner mt_md--10 mt_sm--10">
                            <div class="content text-start">
                                <span class="pre-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.home.head.sub_title' | translate }}</span>
                                <h1 class="title" data-sal-delay="250" data-sal="slide-up" data-sal-duration="800">{{ 'page.home.head.title' | translate }}</h1>
                                <p class="description" data-sal-delay="350" data-sal="slide-up" data-sal-duration="800">{{ 'page.home.head.description' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 order-1 order-lg-2">
                        <div class="banner-image">
                            <div class="thumbnail" data-sal-delay="150" data-sal="fade" data-sal-duration="800"><img src="assets/images/school/logo-alsk.png" alt="Banner Images" /></div>
                            <div class="learner-badge">
                                <div class="badge-inner"><span class="viewer">2K</span><span>{{ 'page.home.head.enrolled' | translate }}</span><span>{{ 'page.home.head.learner' | translate }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="social-text-share">
                    <li><a target="_blank" href="https://www.instagram.com/alskenitra/"><i class="ri-instagram-line"></i></a></li>
                    <li><a target="_blank" href="https://www.facebook.com/Americanlanguageschool/"><i class="icon-Fb"></i></a></li>
                    <li><a href="https://wa.me/212770881110"><i class="ri-whatsapp-line"></i></a></li>
                </ul>
                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1"><img src="assets/images/shapes/shape-05-03.png" alt="Shape Thumb" /></div>
                    <div class="shape-image shape-image-2"><img src="assets/images/shapes/shape-05-04.png" alt="Shape Thumb" /></div>
                    <div class="shape-image shape-image-3"><img src="assets/images/shapes/shape-19-04.png" alt="Shape Thumb" /></div>
                    <div class="shape-image shape-image-4"><img src="assets/images/shapes/shape-01-04.png" alt="Shape Thumb" /></div>
                    <div class="shape-image shape-image-5"><img src="assets/images/shapes/shape-01-03.png" alt="Shape Thumb" /></div>
                    <div class="shape-image shape-image-6"><img src="assets/images/shapes/shape-06.png" alt="Shape Thumb" /></div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-image shape-image-left eduvibe-home-4-hero-svg" data-sal>
                    <svg width="223" height="272" viewBox="0 0 223 272" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 11.0378C15 5.37117 49.6 -3.46216 76 6.53784C109 19.0378 106.5 62.0378 81.5 91.0378C56.5 120.038 43 138.038 49 166.538C55 195.038 94 189.038 122.5 152.538C151 116.038 191.5 107.038 211.5 138.538C231.5 170.038 216.5 199.038 196 209.538C175.5 220.038 144 198.538 162.5 176.538C181 154.538 213 183.538 202 213.038C191 242.538 158.5 255.038 152 270.538" stroke="#BCBDEC" stroke-width="2" />
                    </svg>
                </div>

                <div class="shape-image shape-image-right eduvibe-home-4-hero-svg" data-sal>
                    <svg width="233" height="348" viewBox="0 0 233 348" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5304 336C8.69704 330.667 -0.269631 314.6 2.53037 293C6.03037 266 33.0312 252 51.0312 264.5C69.0312 277 74.0312 302 81.0312 316C88.0312 330 112.031 351.5 141.531 345C171.031 338.5 176.031 324.5 166.031 300.5C156.031 276.5 118.031 273.5 97.5312 266.5C77.0312 259.5 51.5312 248 56.5312 219C61.5312 190 90.0312 195 102.031 209C114.031 223 148.531 232.5 161.531 215C174.531 197.5 163.031 180 150.531 181C138.031 182 129.531 193.5 135.031 205.5C140.531 217.5 170.531 229 191.031 202.5C211.531 176 195.031 152.5 180.031 134.5C165.031 116.5 146.031 90 160.531 50.5C175.031 11 213.031 2.5 232.031 2" stroke="#FFC9C9" stroke-width="2" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
    <!-- End Sldier Area  -->


    <!-- Start About Area  -->
    <div class="eduvibe-home-five-about edu-about-area about-style-6 edu-section-gap bg-color-white">
        <div class="container eduvibe-animated-shape">
            <div class="row g-5 align-items-center">
                <div class="col-xl-5 col-lg-6">
                    <div class="about-image-gallery">
                        <img class="image-1 w-100" src="assets/images/school/about-img-school.png" alt="About Images">
                        <div class="badge-icon">
                            <div class="badge-inner">
                                <img src="assets/images/logo/logo.png" alt="Icon Images">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 offset-xl-1">
                    <div class="inner mt_md--40 mt_sm--20">
                        <div class="section-title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <span class="pre-title">{{ 'page.home.about_us.sub_title' | translate }}</span>
                            <h3 class="title">{{ 'page.home.about_us.title' | translate }}</h3>
                        </div>
                        <p class="description line-before mt--40 mb--40" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.home.about_us.description' | translate }}</p>
                        <div class="feature-list-wrapper" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="feature-list"><i class="icon-checkbox-circle-fill"></i>{{ 'page.home.about_us.feature_1' | translate }}</div>
                            <div class="feature-list"><i class="icon-checkbox-circle-fill"></i>{{ 'page.home.about_us.feature_2' | translate }}</div>
                            <div class="feature-list"><i class="icon-checkbox-circle-fill"></i>{{ 'page.home.about_us.feature_3' | translate }}</div>
                            <div class="feature-list"><i class="icon-checkbox-circle-fill"></i>{{ 'page.home.about_us.feature_4' | translate }}</div>
                        </div>
                        <div class="read-more-btn mt--75 mt_lg--30 mt_md--40 mt_sm--40" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <a class="edu-btn" href="/about-us">{{ 'page.home.about_us.know' | translate }}<i class="icon-arrow-right-line-right"></i></a>
                            <a class="info-btn" href="tel:+212537378540"><i class="icon-call"></i>05 37 37 85 40 / 07 70 88 11 10</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-image shape-image-1">
                    <img src="assets/images/shapes/shape-05-04.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-2">
                    <img src="assets/images/shapes/shape-33.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-3">
                    <img src="assets/images/shapes/shape-13-05.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-4">
                    <img src="assets/images/shapes/shape-01-03.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-5">
                    <img src="assets/images/shapes/shape-06.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    </div>
    <!-- End About Area  -->

    <div class="eduvibe-home-five-progress edu-service-area edu-section-gap bg-image">
        <!-- Start Service Area  -->
        <div class="container eduvibe-animated-shape">
            <div class="row">
                <div class="col-lg-12">
                    <div class="section-title text-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <span class="pre-title">{{ 'page.home.process.title' | translate }}</span>
                        <h3 class="title">{{ 'page.home.process.sub_title' | translate }}</h3>
                    </div>
                </div>
            </div>
            <div class="row g-5 mt--20 mb--100">
                <!-- Start Service Grid  -->
                <div class="service-card-single col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="service-card service-card-7 shape-bg-1">
                        <div class="inner">
                            <div class="icon">
                                <a href="#">
                                    <i class="icon-book-line"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="#">{{ 'page.home.process.process_1_title' | translate }}</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

                <!-- Start Service Grid  -->
                <div class="service-card-single col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="service-card service-card-7 shape-bg-2">
                        <div class="inner">
                            <div class="icon">
                                <a href="#">
                                    <i class="icon-shopping-basket-2-line"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="#">{{ 'page.home.process.process_2_title' | translate }}</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->

                <!-- Start Service Grid  -->
                <div class="service-card-single col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="service-card service-card-7 shape-bg-3">
                        <div class="inner">
                            <div class="icon">
                                <a href="#">
                                    <i class="icon-trophy-line"></i>
                                </a>
                            </div>
                            <div class="content">
                                <h6 class="title"><a href="#">{{ 'page.home.process.process_3_title' | translate }}</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Service Grid  -->
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-image shape-image-1">
                    <img src="assets/images/shapes/shape-07-02.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-2">
                    <img src="assets/images/shapes/shape-04-07.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-3">
                    <img src="assets/images/shapes/shape-13-11.png" alt="Shape Thumb" />
                </div>
            </div>

        </div>
        <!-- End Service Area  -->
    </div>


    <!-- Start Newsletter Area  -->
    <div class="edu-newsletter-area newsletter-overlay-to-another">
        <div class="container newsletter-style-5 bg-color-primary">
            <div class="row align-items-center g-5">
                <div class="col-lg-7">
                    <div class="inner">
                        <div class="section-title text-start text-white" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <span class="pre-title">{{ 'page.home.help.sub_title' | translate }}</span>
                            <h3 class="title">{{ 'page.home.help.title' | translate }}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="cta-btn text-start text-lg-end">
                        <a class="edu-btn btn-white m-1" target="_blank" href="tel:212537378540"> 
                            <i class="ri-phone-line"></i> 
                            +212 537 37 85 40
                        </a>
                        <a class="edu-btn btn-white m-1" target="_blank" href="https://wa.me/212770881110"> 
                            <i class="ri-whatsapp-line"></i> 
                            +212 770 88 11 10
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Newsletter Area  -->

    <div class="eduvibe-home-four-testimonial edu-testimonial-area edu-section-gap bg-color-white">
        <!-- Start Testimonial Area  -->
        <div class="container eduvibe-animated-shape">
            <div class="row">
                <div class="col-lg-6">
                    <div class="testimonial-left-image pr--80">
                        <div class="thumbnail">
                            <div class="circle-image-wrapper">
                                <img class="radius-round" src="assets/images/school/client-banner-left.png" alt="testimonial Images">
                                <div class="circle-image">
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="testimonial-wrapper">
                        <div class="section-title text-start mb--40" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <span class="pre-title">{{ 'page.home.testimonial.title' | translate }}</span>
                            <h3 class="title">{{ 'page.home.testimonial.sub_title' | translate }}</h3>
                        </div>

                        <div class="testimonial-activation testimonial-style-2">
                            <!-- Start Single Testimonial  -->
                            <div class="testimonial-nav-activation">
                                <div class="testimonial-nav-content">
                                    <p class="description">{{ 'page.home.testimonial.feedback_1_description' | translate }}</p>
                                    <div class="client-info">
                                        <h6 class="title">{{ 'page.home.testimonial.feedback_1_author' | translate }}</h6>
                                        <span class="designation">{{ 'page.home.testimonial.feedback_1_author_job' | translate }}</span>
                                    </div>
                                </div>
                                <div class="testimonial-nav-content">
                                    <p class="description">{{ 'page.home.testimonial.feedback_2_description' | translate }}</p>
                                    <div class="client-info">
                                        <h6 class="title">{{ 'page.home.testimonial.feedback_2_author' | translate }}</h6>
                                        <span class="designation">{{ 'page.home.testimonial.feedback_2_author_job' | translate }}</span>
                                    </div>
                                </div>
                                <div class="testimonial-nav-content">
                                    <p class="description">{{ 'page.home.testimonial.feedback_3_description' | translate }}</p>
                                    <div class="client-info">
                                        <h6 class="title">{{ 'page.home.testimonial.feedback_3_author' | translate }}</h6>
                                        <span class="designation">{{ 'page.home.testimonial.feedback_3_author_job' | translate }}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- End Single Testimonial  -->

                            <div class="testimonial-nav-wrapper">
                                <div class="testimonial-nav-button">
                                    <div class="single-thumbnail">
                                        <img src="assets/images/testimonial/aziz_bouabid.jpg" alt="Client Images">
                                        <div class="loader-container">
                                            <div class="circle-loader-wrap">
                                                <div class="left-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                                <div class="right-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-thumbnail">
                                        <img src="assets/images/testimonial/ali_slitine_alaoui.jpg" alt="Client Images">
                                        <div class="loader-container">
                                            <div class="circle-loader-wrap">
                                                <div class="left-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                                <div class="right-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-thumbnail">
                                        <img src="assets/images/testimonial/mbarek_ahellal.jpg" alt="Client Images">
                                        <div class="loader-container">
                                            <div class="circle-loader-wrap">
                                                <div class="left-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                                <div class="right-wrap">
                                                    <div class="loader"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-image shape-image-1">
                    <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-2">
                    <img src="assets/images/shapes/shape-25-01.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-3">
                    <img src="assets/images/shapes/shape-11-04.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
        <!-- End Countdown Area  -->
    </div>

    <div class="eduvibe-home-four-blog bg-image edu-blog-area edu-section-gap">
        <div class="container eduvibe-animated-shape">
            <div class="row g-5">
                <div class="col-lg-6 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="section-title text-start">
                        <span class="pre-title">{{ 'page.home.latest_news.title' | translate }}</span>
                        <h3 class="title">{{ 'page.home.latest_news.sub_title' | translate }}</h3>
                    </div>
                </div>
            </div>
            <div class="row g-5 mt--20">

                <!-- Start Blog Grid  -->
                <div class="col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="edu-blog blog-type-2 variation-2 radius-small">
                        <div class="inner">
                            <div class="thumbnail">
                                <a href="/blog/online-learning-glossary">
                                    <img src="assets/images/school/img3.JPG" alt="Blog Images">
                                </a>
                            </div>
                            <div class="content">
                                <div class="blog-date-status">
                                    <span class="day">20</span>
                                    <span class="month">{{ 'page.home.latest_news.article_1.month' | translate }}</span>
                                </div>
                                <div class="status-group status-style-5">
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_1.tag' | translate }}</a>
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_1.min_read' | translate }}</a>
                                </div>
                                <h5 class="title"><a href="/blog/online-learning-glossary">{{ 'page.home.latest_news.article_1.title' | translate }}</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Blog Grid  -->

                <!-- Start Blog Grid  -->
                <div class="col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="edu-blog blog-type-2 variation-2 radius-small">
                        <div class="inner">
                            <div class="thumbnail">
                                <a href="/blog/relaxing-after-work">
                                    <img src="assets/images/school/img4.JPG" alt="Blog Images">
                                </a>
                            </div>
                            <div class="content">
                                <div class="blog-date-status">
                                    <span class="day">06</span>
                                    <span class="month">{{ 'page.home.latest_news.article_2.month' | translate }}</span>
                                </div>
                                <div class="status-group status-style-5">
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_2.tag' | translate }}</a>
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_2.min_read' | translate }}</a>
                                </div>
                                <h5 class="title"><a href="/blog/relaxing-after-work">{{ 'page.home.latest_news.article_2.title' | translate }}</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Blog Grid  -->

                <!-- Start Blog Grid  -->
                <div class="col-lg-4 col-md-6 col-12" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <div class="edu-blog blog-type-2 variation-2 radius-small">
                        <div class="inner">
                            <div class="thumbnail">
                                <a href="/blog/why-you-should-read-every-day">
                                    <img src="assets/images/school/img3.JPG" alt="Blog Images">
                                </a>
                            </div>
                            <div class="content">
                                <div class="blog-date-status">
                                    <span class="day">12</span>
                                    <span class="month">{{ 'page.home.latest_news.article_3.month' | translate }}</span>
                                </div>
                                <div class="status-group status-style-5">
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_3.tag' | translate }}</a>
                                    <a href="#" class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_3.min_read' | translate }}</a>
                                </div>
                                <h5 class="title"><a href="/blog/why-you-should-read-every-day">{{ 'page.home.latest_news.article_3.title' | translate }}</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Blog Grid  -->
            </div>
            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-image shape-image-1">
                    <img src="assets/images/shapes/shape-32.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-2">
                    <img src="assets/images/shapes/shape-03-08.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-3">
                    <img src="assets/images/shapes/shape-27-01.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
<div class="rn-progress-parent">
    <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
