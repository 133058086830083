import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import {FaqComponent} from "./components/pages/faq/faq.component";
import {ContactUsComponent} from "./components/pages/contact-us/contact-us.component";
import {BlogComponent} from "./components/pages/blog/blog.component";
import {OnlineLearningGlossaryComponent} from "./components/pages/blog/online-learning-glossary/online-learning-glossary.component";
import {RelaxingAfterWorkComponent} from "./components/pages/blog/relaxing-after-work/relaxing-after-work.component";
import {WhyYouShouldReadEveryDayComponent} from "./components/pages/blog/why-you-should-read-every-day/why-you-should-read-every-day.component";
import {AboutUsComponent} from "./components/pages/about-us/about-us.component";
import {PrivacyPolicyComponent} from "./components/pages/privacy-policy/privacy-policy.component";
import {TermsConditionsComponent} from "./components/pages/terms-conditions/terms-conditions.component";
import {ProgramsComponent} from "./components/pages/programs/programs.component";

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'about-us', component: AboutUsComponent},
    {path: 'programs', component: ProgramsComponent},
    {path: 'blog', component: BlogComponent},
        {path: 'blog/online-learning-glossary', component: OnlineLearningGlossaryComponent},
        {path: 'blog/relaxing-after-work', component: RelaxingAfterWorkComponent},
        {path: 'blog/why-you-should-read-every-day', component: WhyYouShouldReadEveryDayComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'contact-us', component: ContactUsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
