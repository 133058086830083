<header class="edu-header disable-transparent  header-sticky">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-xl-2 col-md-2 col-2">
                <div class="logo">
                    <a href="/">
                        <img class="logo-light" src="assets/images/logo/logo.png" alt="Site Logo">
                    </a>
                </div>
            </div>
            <div class="col-lg-7 d-none d-xl-block">
                <nav class="mainmenu-nav d-none d-lg-block">
                    <ul class="mainmenu">
                        <li><a href="/">{{ 'navbar.menu.home' | translate }}</a></li>
                        <li><a href="/programs">{{ 'navbar.menu.programs' | translate }}</a></li>
                        <li><a href="/about-us">{{ 'navbar.menu.about_us' | translate }}</a></li>
<!--                        <li><a href="/blog">{{ 'navbar.menu.blog' | translate }}</a></li>-->
<!--                        <li><a href="/faq">{{ 'navbar.menu.faq' | translate }}</a></li>-->
                        <li><a href="/contact-us">{{ 'navbar.menu.contact-us' | translate }}</a></li>
                    </ul>
                </nav>
            </div>
            <div class="col-auto">
                <div class="header-right d-flex justify-content-end">
                    <div class="header-quote">
                        <div class="quote-icon quote-user">
                            <a href="https://als.eduweb.ma/" target="_blank" class="fw-bold">{{ 'navbar.login' | translate }} <i class="ri-user-line ml--10"></i></a>
                        </div>
                        <div class="hamberger quote-icon d-block d-xl-none">
                            <button class="hamberger-button"><i class="ri-menu-line"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <nav class="mainmenu-nav d-none d-lg-block">
                    <ul class="mainmenu">
                        <li class="has-droupdown">
                            <a href="#"><img src="assets/images/flag/{{currentLang}}.png" class="lang-flag"></a>
                            <ul class="submenu">
                                <li><a href="javascript:;" (click)="changeSiteLanguage('fr')"><img src="assets/images/flag/fr.png" class="lang-flag"> {{ 'navbar.lang.fr' | translate }}</a></li>
                                <li><a href="javascript:;" (click)="changeSiteLanguage('en')"><img src="assets/images/flag/en.png" class="lang-flag"> {{ 'navbar.lang.en' | translate }}</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</header>

<div class="popup-mobile-menu">
    <div class="inner">
        <div class="header-top">
            <div class="logo">
                <a href="index.html">
                    <img src="assets/images/logo/logo.png" alt="Site Logo">
                </a>
            </div>
            <div class="close-menu">
                <button class="close-button">
                    <i class="ri-close-line"></i>
                </button>
            </div>
        </div>
        <ul class="mainmenu">
            <li><a href="/">{{ 'navbar.menu.home' | translate }}</a></li>
            <li><a href="/about-us">{{ 'navbar.menu.about_us' | translate }}</a></li>
            <li><a href="/programs">{{ 'navbar.menu.programs' | translate }}</a></li>
            <li><a href="/contact-us">{{ 'navbar.menu.contact-us' | translate }}</a></li>
            <li><a href="javascript:;" (click)="changeSiteLanguage('fr')">{{ 'navbar.lang.fr' | translate }}</a></li>
            <li><a href="javascript:;" (click)="changeSiteLanguage('en')">{{ 'navbar.lang.en' | translate }}</a></li>
        </ul>
    </div>
</div>
