<!-- Start Footer Area  -->
<footer class="eduvibe-footer-one edu-footer footer-style-default">
    <div class="footer-top">
        <div class="container eduvibe-animated-shape">
            <div class="row g-5">
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="edu-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img class="logo-light" src="assets/images/logo/logo-white.png" width="75%" alt="Site Logo">
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="edu-footer-widget explore-widget">
                        <h5 class="widget-title">{{ 'footer.menu.title.explore' | translate }}</h5>
                        <div class="inner">
                            <ul class="footer-link link-hover">
                                <li><a href="about-us"><i class="icon-Double-arrow"></i>{{ 'footer.menu.about_us' | translate }}</a></li>
                                <li><a href="terms-conditions"><i class="icon-Double-arrow"></i>{{ 'footer.menu.terms_conditions' | translate }}</a></li>
                                <li><a href="privacy-policy"><i class="icon-Double-arrow"></i>{{ 'footer.menu.privacy_policy' | translate }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="edu-footer-widget quick-link-widget">
                        <h5 class="widget-title">{{ 'footer.menu.title.useful_links' | translate }}</h5>
                        <div class="inner">
                            <ul class="footer-link link-hover">
                                <li><a href="contact-us"><i class="icon-Double-arrow"></i>{{ 'footer.menu.contact-us' | translate }}</a></li>
                                <li><a href="blog"><i class="icon-Double-arrow"></i>{{ 'footer.menu.blog' | translate }}</a></li>
                                <li><a href="faq"><i class="icon-Double-arrow"></i>{{ 'footer.menu.faq' | translate }}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                    <div class="edu-footer-widget">
                        <h5 class="widget-title">{{ 'footer.menu.title.contact_info' | translate }}</h5>
                        <div class="inner">
                            <div class="widget-information">
                                <ul class="information-list">
                                    <li><i class="icon-phone-fill"></i><a href="tel: 05 37 37 85 40">05 37 37 85 40</a></li>
                                    <li><i class="ri-whatsapp-line"></i><a href="https://wa.me/212770881110">07 70 88 11 10</a></li>
                                    <li><i class="icon-mail-line-2"></i><a target="_blank" href="mailto:als.kenitra@gmail.com">{{ 'global.email' | translate }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-md-block d-none">
                <div class="shape-image shape-image-1">
                    <img src="assets/images/shapes/shape-21-01.png" alt="Shape Thumb" />
                </div>
                <div class="shape-image shape-image-2">
                    <img src="assets/images/shapes/shape-35.png" alt="Shape Thumb" />
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area copyright-default">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inner text-center">
                        <p>{{ 'footer.copyright' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area  -->
