<div class="main-wrapper">

    <app-navbar></app-navbar>

    <div class="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
        <div class="container eduvibe-animated-shape">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-inner text-start">
                        <div class="page-title">
                            <h3 class="title">{{ 'page.faq.head.title' | translate }}</h3>
                        </div>
                        <nav class="edu-breadcrumb-nav">
                            <ol class="edu-breadcrumb d-flex justify-content-start liststyle">
                                <li class="breadcrumb-item"><a href="/">{{ 'breadcrumbs.home' | translate }}</a></li>
                                <li class="separator"><i class="ri-arrow-drop-right-line"></i></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ 'breadcrumbs.faq' | translate }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-5">
                        <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-6">
                        <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Start Accordion Area  -->
    <div class="edu-accordion-area eduvibe-faq-page accordion-shape-1 edu-section-gap bg-color-white">
        <div class="wrapper">
            <div class="container eduvibe-animated-shape">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <span class="pre-title">{{ 'page.faq.title' | translate }}</span>
                            <h3 class="title">{{ 'page.faq.sub_title' | translate }}</h3>
                        </div>
                    </div>
                </div>

                <div class="tab-content" id="eduTabContent">
                    <div class="tab-pane fade show active" id="edutabone" role="tabpanel" aria-labelledby="edutabone-tab">
                        <div class="row g-5 align-items-center mt--20">
                            <div class="col-lg-6">
                                <div class="accordion-style-1">
                                    <div class="edu-accordion" id="accordionExample1">
                                        <div class="edu-accordion-item">

                                            <div class="edu-accordion-header" id="headingOne">
                                                <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    {{ 'page.faq.question_1' | translate }}
                                                </button>
                                            </div>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_1' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="headingTwo">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    {{ 'page.faq.question_2' | translate }}
                                                </button>
                                            </div>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_2' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="headingThree">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    {{ 'page.faq.question_3' | translate }}
                                                </button>
                                            </div>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample1">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_3' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="twoheadingOne">
                                                <button class="edu-accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#twocollapseOne" aria-expanded="true" aria-controls="twocollapseOne">
                                                    {{ 'page.faq.question_4' | translate }}
                                                </button>
                                            </div>
                                            <div id="twocollapseOne" class="accordion-collapse collapse" aria-labelledby="twoheadingOne" data-bs-parent="#accordionExample2">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_4' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="accordion-style-1">
                                    <div class="edu-accordion" id="accordionExample2">

                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="twoheadingTwo">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#twocollapseTwo" aria-expanded="false" aria-controls="twocollapseTwo">
                                                    {{ 'page.faq.question_5' | translate }}
                                                </button>
                                            </div>
                                            <div id="twocollapseTwo" class="accordion-collapse collapse" aria-labelledby="twoheadingTwo" data-bs-parent="#accordionExample2">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_5' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="twoheadingThree">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#twocollapseThree" aria-expanded="false" aria-controls="twocollapseThree">
                                                    {{ 'page.faq.question_6' | translate }}
                                                </button>
                                            </div>
                                            <div id="twocollapseThree" class="accordion-collapse collapse" aria-labelledby="twoheadingThree" data-bs-parent="#accordionExample2">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_6' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="twoheadingFour">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#twocollapseFour" aria-expanded="false" aria-controls="twocollapseFour">
                                                    {{ 'page.faq.question_7' | translate }}
                                                </button>
                                            </div>
                                            <div id="twocollapseFour" class="accordion-collapse collapse" aria-labelledby="twoheadingFour" data-bs-parent="#accordionExample2">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_7' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edu-accordion-item">
                                            <div class="edu-accordion-header" id="twoheadingFive">
                                                <button class="edu-accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#twocollapseFive" aria-expanded="false" aria-controls="twocollapseFour">
                                                    {{ 'page.faq.question_8' | translate }}
                                                </button>
                                            </div>
                                            <div id="twocollapseFive" class="accordion-collapse collapse" aria-labelledby="twoheadingFive" data-bs-parent="#accordionExample2">
                                                <div class="edu-accordion-body">
                                                    {{ 'page.faq.answer_8' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- End Accordion Area  -->

    <app-footer></app-footer>

</div>
<div class="rn-progress-parent">
    <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
