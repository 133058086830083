import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {
    currentLang:String;
    languageList = [
        { code: 'en', label: 'English' },
        { code: 'fr', label: 'French' },
    ];

    constructor(private translate: TranslateService) {

    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang;
    }

    changeSiteLanguage(localeCode: string): void {
        const selectedLanguage =

            this.languageList
            .find((language) => language.code === localeCode)
            ?.code.toString();
        if (selectedLanguage) {
            this.currentLang = selectedLanguage;
            this.translate.use(localeCode);
        }
        const currentLanguage = this.translate.currentLang;
        console.log('currentLanguage', currentLanguage);
    }

}
