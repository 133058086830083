import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { OnlineLearningGlossaryComponent } from "./components/pages/blog/online-learning-glossary/online-learning-glossary.component";
import { RelaxingAfterWorkComponent } from "./components/pages/blog/relaxing-after-work/relaxing-after-work.component";
import { WhyYouShouldReadEveryDayComponent } from "./components/pages/blog/why-you-should-read-every-day/why-you-should-read-every-day.component";
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { TermsConditionsComponent } from "./components/pages/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { NgxTranslateModule } from "./translate/translate.module";
import { ProgramsComponent } from "./components/pages/programs/programs.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    ProgramsComponent,
    BlogComponent,
    OnlineLearningGlossaryComponent,
    RelaxingAfterWorkComponent,
    WhyYouShouldReadEveryDayComponent,
    FaqComponent,
    ContactUsComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxTranslateModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
