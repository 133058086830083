    <div class="main-wrapper">

        <app-navbar></app-navbar>

        <div class="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
            <div class="container eduvibe-animated-shape">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-inner text-start">
                            <div class="page-title">
                                <h3 class="title">{{ 'page.about_us.head.title' | translate }}</h3>
                            </div>
                            <nav class="edu-breadcrumb-nav">
                                <ol class="edu-breadcrumb d-flex justify-content-start liststyle">
                                    <li class="breadcrumb-item"><a href="/">{{ 'breadcrumbs.home' | translate }}</a></li>
                                    <li class="separator"><i class="ri-arrow-drop-right-line"></i></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{ 'breadcrumbs.about_us' | translate }}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>

                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div class="shape-image shape-image-1">
                            <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-2">
                            <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-3">
                            <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-4">
                            <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-5">
                            <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                        </div>
                        <div class="shape-image shape-image-6">
                            <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class=" eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div class="container eduvibe-animated-shape">
                <div class="row row--50">
                    <div class="col-lg-6">
                        <div class="about-image-gallery">
                            <div class="eduvibe-about-1-img-wrapper">
                                <img class="image-1" src="assets/images/school/img2.JPG" alt="About Images">
                                <span class="eduvibe-about-blur"><img src="assets/images/about/about-07/about-blur.png" alt="About Blur"></span>
                            </div>
                            <div class="circle-image-wrapper">
                                <img class="image-2" width="90%" src="assets/images/school/logo-alsk.png" alt="About Images">
                                <div class="circle-image"><span></span></div>
                            </div>
                            <div class="finished-session">
                                <div class="inner">
                                    <div class="text">2.98</div>
                                    <span class="finished-title">
                                        {{ 'page.about_us.finished_session' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="inner mt_md--40 mt_sm--40">
                            <div class="section-title sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span class="pre-title">{{ 'page.about_us.sub_title' | translate }}</span>
                                <h3 class="title">{{ 'page.about_us.title' | translate }}</h3>
                            </div>

                            <p class="description mt--40 mt_md--20 mt_sm--20 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.about_us.description' | translate }}</p>
                            <h6 class="subtitle mb--20 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.about_us.statistics.title' | translate }}</h6>
                            <div class="about-feature-list">
                                <div class="row g-5">
                                    <!-- Start Single Feature  -->
                                    <div class="col-lg-6 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                        <div class="feature-style-3">
                                            <div class="feature-content">
                                                <h6 class="feature-title">90%</h6>
                                                <p class="feature-description">{{ 'page.about_us.statistics.students' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Single Feature  -->

                                    <!-- Start Single Feature  -->
                                    <div class="col-lg-6 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                        <div class="feature-style-3">
                                            <div class="feature-content">
                                                <h6 class="feature-title">9/10</h6>
                                                <p class="feature-description">{{ 'page.about_us.statistics.users' | translate }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Single Feature  -->
                                </div>
                            </div>

                            <div class="read-more-btn mt--40 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-11-05.png" alt="Shape Thumb">
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-08-01.png" alt="Shape Thumb">
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-30.png" alt="Shape Thumb">
                    </div>
                    <div class="shape shape-1"><span class="shape-dot"></span></div>
                </div>
            </div>
        </div>

        <div class="col-lg-12 ptb--60 ptb_md--40 ptb_sm--40 style-variation3">
            <div class="section-title text-center sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                <img src="assets/images/about/photo.png" width="200px">
                <h3 class="title mb-4">{{ 'page.about_us.word_founder.title' | translate }}</h3>
                <figcaption class="blockquote-footer">
                    {{ 'page.about_us.word_founder.name' | translate }}
                </figcaption>
            </div>

            <div class="row">
                <div class="col-10 offset-1">
                    <p class="blockquote-style-1" style="font-size: 1.2em; text-align: justify; font-family: 'kalam', sans-serif;">
                        {{ 'page.about_us.word_founder.description' | translate }}
                    </p>

                    
                </div>
            </div>

        </div>

        <div class="eduvibe-home-five-progress edu-service-area edu-section-gap bg-image">
            <!-- Start Service Area  -->
            <div class="container eduvibe-animated-shape">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-title text-center sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <span class="pre-title">{{ 'page.home.process.title' | translate }}</span>
                            <h3 class="title">{{ 'page.home.process.sub_title' | translate }}</h3>
                        </div>
                    </div>
                </div>
                <div class="row g-5 mt--20">
                    <!-- Start Service Grid  -->
                    <div class="service-card-single col-lg-4 col-md-6 col-12 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div class="service-card service-card-7 shape-bg-1">
                            <div class="inner">
                                <div class="icon">
                                    <a href="#">
                                        <i class="icon-book-line"></i>
                                    </a>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="#">{{ 'page.home.process.process_1_title' | translate }}</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Service Grid  -->

                    <!-- Start Service Grid  -->
                    <div class="service-card-single col-lg-4 col-md-6 col-12 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div class="service-card service-card-7 shape-bg-2">
                            <div class="inner">
                                <div class="icon">
                                    <a href="#">
                                        <i class="icon-shopping-basket-2-line"></i>
                                    </a>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="#">{{ 'page.home.process.process_2_title' | translate }}</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Service Grid  -->

                    <!-- Start Service Grid  -->
                    <div class="service-card-single col-lg-4 col-md-6 col-12 sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div class="service-card service-card-7 shape-bg-3">
                            <div class="inner">
                                <div class="icon">
                                    <a href="#">
                                        <i class="icon-trophy-line"></i>
                                    </a>
                                </div>
                                <div class="content">
                                    <h6 class="title"><a href="#">{{ 'page.home.process.process_3_title' | translate }}</a></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Service Grid  -->
                </div>

                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-07-02.png" alt="Shape Thumb">
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-04-07.png" alt="Shape Thumb">
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-13-11.png" alt="Shape Thumb">
                    </div>
                </div>

            </div>
            <!-- End Service Area  -->
        </div>

        <div class="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
            <div class="container eduvibe-animated-shape">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="thumbnail mb--50">
                            <div class="shape-image image-1">
                                <img src="assets/images/school/img1-gal.png" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 80}" alt="Gallery Images" style="transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
                            </div>
                            <div class="shape-image image-2">
                                <img src="assets/images/school/img2-gal.png" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: -60}" alt="Gallery Images" style="transform:translate3d(0px, -0.008px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(0px, -0.008px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
                            </div>
                            <div class="shape-image image-3">
                                <img src="assets/images/school/img4-gal.png" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: 80}" alt="Gallery Images" style="transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(0px, 0.029px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
                            </div>
                            <div class="shape-image image-4">
                                <img src="assets/images/school/img3-gal.png" data-parallax="{&quot;x&quot;: 0, &quot;y&quot;: -80}" alt="Gallery Images" style="transform:translate3d(0px, -0.012px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); -webkit-transform:translate3d(0px, -0.012px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1); ">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="animated-image shape-image-1">
                        <img src="assets/images/shapes/shape-04-01.png" alt="Shape Thumb">
                    </div>
                    <div class="shape shape-1"><span class="shape-dot"></span></div>
                    <div class="shape shape-2"><span class="shape-dot"></span></div>
                </div>
            </div>

            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6">
                        <div class="our-mission">
                            <div class="section-title text-start sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h3 class="title">{{ 'page.about_us.our_mission.title' | translate }}</h3>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_mission.description_1' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_mission.description_2' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_mission.description_3' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="our-vision">
                            <div class="section-title text-start sal-animate" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <h3 class="title">{{ 'page.about_us.our_vision.title' | translate }}</h3>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_vision.description_1' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_vision.description_2' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_vision.description_3' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_vision.description_4' | translate }}</p>
                                <p class="mt--30 mb--0">{{ 'page.about_us.our_vision.description_5' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="counterup-style-2 bg-color-primary radius-small ptb--80 ">
                <div class="row g-5">
                    <!-- Start Single Counter  -->
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 line-separator">
                        <div class="edu-counterup-2 text-center">
                            <div class="inner">
                                <div class="icon">
                                    <i class="icon-Bag"></i>
                                </div>
                                <div class="content">
                                    <h3 class="counter"><span class="odometer" data-count="55000">00</span>
                                        <span class="after-icon">+</span>
                                    </h3>
                                    <span class="subtitle">{{ 'page.about_us.counter_up_1' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Counter  -->

                    <!-- Start Single Counter  -->
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 line-separator">
                        <div class="edu-counterup-2 text-center">
                            <div class="inner">
                                <div class="icon">
                                    <i class="icon-trophy"></i>
                                </div>
                                <div class="content">
                                    <h3 class="counter"><span class="odometer" data-count="60">00</span>
                                        <span class="after-icon">+</span>
                                    </h3>
                                    <span class="subtitle">{{ 'page.about_us.counter_up_2' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Counter  -->

                    <!-- Start Single Counter  -->
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 line-separator">
                        <div class="edu-counterup-2 text-center">
                            <div class="inner">
                                <div class="icon">
                                    <i class="icon-Open-book"></i>
                                </div>
                                <div class="content">
                                    <h3 class="counter"><span class="odometer" data-count="2240">00</span>
                                        <span class="after-icon">+</span>
                                    </h3>
                                    <span class="subtitle">{{ 'page.about_us.counter_up_3' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Counter  -->

                    <!-- Start Single Counter  -->
                    <div class="col-lg-3 col-md-6 col-sm-6 col-12 line-separator">
                        <div class="edu-counterup-2 text-center">
                            <div class="inner">
                                <div class="icon">
                                    <i class="icon-presentation"></i>
                                </div>
                                <div class="content">
                                    <h3 class="counter"><span class="odometer" data-count="215">00</span>
                                        <span class="after-icon">+</span>
                                    </h3>
                                    <span class="subtitle">{{ 'page.about_us.counter_up_4' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Single Counter  -->
                </div>
            </div>
        </div>

        <!-- End Counterup Area  -->
        <div class="eduvibe-home-three-testimonial edu-testimonial-area testimonial-four-wrapper edu-section-gap bg-image counterup-overlay-top">
            <div class="container eduvibe-animated-shape">
                <div class="row g-5 align-items-center">
                    <div class="col-lg-8">
                        <div class="testimonial-activation pr--55 pr_lg--0 pr_md--0 pr_sm--0">
                            <!-- Start Tastimonial Card  -->
                            <div class="testimonial-card-box variation-2">
                                <div class="eduvibe-testimonial-three inner testimonial-card-activation-1 slick-arrow-style-2">
                                    <div class="single-card">
                                        <div class="rating">
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                        </div>
                                        <p class="description">“The best place to learn languages in Kenitra...”</p>
                                        <div class="client-info">
                                            <div class="thumbnail">
                                                <img src="assets/images/testimonial/mourad-ounes.png" alt="Client Images">
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Mourad Ounes</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-card">
                                        <div class="rating">
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                        </div>
                                        <p class="description">“Accueil chaleureux Lieux Bien Organisé Satisfaction garantie Relations affectives, j'aime bien Ce lieu”</p>
                                        <div class="client-info">
                                            <div class="thumbnail">
                                                <img src="assets/images/testimonial/hassan-koudia.png" alt="Client Images">
                                            </div>
                                            <div class="content">
                                                <h6 class="title">hassan koudia</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-card">
                                        <div class="rating">
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                            <i class="on icon-Star"></i>
                                        </div>
                                        <p class="description">“I like it”</p>
                                        <div class="client-info">
                                            <div class="thumbnail">
                                                <img src="assets/images/testimonial/azdine-driouche.png" alt="Client Images">
                                            </div>
                                            <div class="content">
                                                <h6 class="title">Azdine Driouche</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Tastimonial Card  -->
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="testimonial-four-right-content">
                            <div class="section-title text-start" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                <span class="pre-title">{{ 'page.about_us.testimonials.sub_title' | translate }}</span>
                                <h3 class="title">{{ 'page.about_us.testimonials.title' | translate }}</h3>
                            </div>
                            <p class="description mt--25 mb--25" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.about_us.description' | translate }}</p>

                            <h6 class="subtitle" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">{{ 'page.about_us.statistics.title' | translate }}</h6>
                            <div class="row g-5">
                                <!-- Start Single Feature  -->
                                <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div class="feature-style-3">
                                        <div class="feature-content">
                                            <h6 class="feature-title">90%</h6>
                                            <p class="feature-description">{{ 'page.about_us.statistics.students' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Single Feature  -->

                                <!-- Start Single Feature  -->
                                <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                    <div class="feature-style-3">
                                        <div class="feature-content">
                                            <h6 class="feature-title">9/10</h6>
                                            <p class="feature-description">{{ 'page.about_us.statistics.users' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Single Feature  -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-04-03.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-08.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-19-03.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-16-01.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>

        <app-footer></app-footer>

    </div>
    <div class="rn-progress-parent">
        <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
    </div>
