<div class="main-wrapper">

    <app-navbar></app-navbar>

    <div class="edu-breadcrumb-area breadcrumb-style-1 ptb--60 ptb_md--40 ptb_sm--40 bg-image">
        <div class="container eduvibe-animated-shape">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-inner text-start">
                        <div class="page-title">
                            <h3 class="title">{{ 'page.blog.head.title' | translate }}</h3>
                        </div>
                        <nav class="edu-breadcrumb-nav">
                            <ol class="edu-breadcrumb d-flex justify-content-start liststyle">
                                <li class="breadcrumb-item"><a href="/">{{ 'breadcrumbs.home' | translate }}</a></li>
                                <li class="separator"><i class="ri-arrow-drop-right-line"></i></li>
                                <li class="breadcrumb-item active" aria-current="page">{{ 'breadcrumbs.blog' | translate }}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div class="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div class="shape-image shape-image-1">
                        <img src="assets/images/shapes/shape-11-07.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-2">
                        <img src="assets/images/shapes/shape-01-02.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-3">
                        <img src="assets/images/shapes/shape-03.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-4">
                        <img src="assets/images/shapes/shape-13-12.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-5">
                        <img src="assets/images/shapes/shape-36.png" alt="Shape Thumb" />
                    </div>
                    <div class="shape-image shape-image-6">
                        <img src="assets/images/shapes/shape-05-07.png" alt="Shape Thumb" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="edu-elements-area edu-section-gap bg-color-white">
        <div class="container">
            <div class="row g-5">
                <div class="col-lg-12">
                    <div class="row g-5">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog/online-learning-glossary"><img src="assets/images/blog/blog-details-01/post-01.jpg" alt="Blog Thumb" /></a>
                                    </div>
                                    <div class="content">
                                        <div class="blog-date-status"><span class="day">06</span><span class="month">{{ 'page.home.latest_news.article_1.month' | translate }}</span></div>
                                        <div class="status-group status-style-5">
                                            <a class="eduvibe-status status-05" href="/blog/online-learning-glossary"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_1.tag' | translate }}</a><span class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_1.min_read' | translate }}</span>
                                        </div>
                                        <h5 class="title"><a href="/blog/online-learning-glossary">{{ 'page.home.latest_news.article_1.title' | translate }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog/relaxing-after-work"><img src="assets/images/blog/blog-details-02/post-01.jpg" alt="Blog Thumb" /></a>
                                    </div>
                                    <div class="content">
                                        <div class="blog-date-status"><span class="day">10</span><span class="month">{{ 'page.home.latest_news.article_2.month' | translate }}</span></div>
                                        <div class="status-group status-style-5">
                                            <a class="eduvibe-status status-05" href="/blog/relaxing-after-work"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_2.tag' | translate }}</a><span class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_2.min_read' | translate }}</span>
                                        </div>
                                        <h5 class="title"><a href="/blog/relaxing-after-work">{{ 'page.home.latest_news.article_2.title' | translate }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="edu-blog blog-type-2 variation-2 radius-small bg-color-gray">
                                <div class="inner">
                                    <div class="thumbnail">
                                        <a href="/blog/why-you-should-read-every-day"><img src="assets/images/blog/post-02/post-06.jpg" alt="Blog Thumb" /></a>
                                    </div>
                                    <div class="content">
                                        <div class="blog-date-status"><span class="day">12</span><span class="month">{{ 'page.home.latest_news.article_3.month' | translate }}</span></div>
                                        <div class="status-group status-style-5">
                                            <a class="eduvibe-status status-05" href="/blog/why-you-should-read-every-day"><i class="icon-price-tag-3-line"></i> {{ 'page.home.latest_news.article_3.tag' | translate }}</a><span class="eduvibe-status status-05"><i class="icon-time-line"></i> 3 {{ 'page.home.latest_news.article_3.min_read' | translate }}</span>
                                        </div>
                                        <h5 class="title"><a href="/blog/why-you-should-read-every-day">{{ 'page.home.latest_news.article_3.title' | translate }}</a></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>
<div class="rn-progress-parent">
    <svg class="rn-back-circle svg-inner" width="100%" height="100%" viewBox="-1 -1 102 102">
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
</div>
